import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from "./Media";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: [
      "Professional Coder.",
      "Full Stack Developer.",
      "MERN Stack Developer.",
      "Laravel Developer.",
      "Software Engineer",
    ],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20 gap-2-5">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-6xl fs-muneeb font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Muneeb</span>
        </h1>
        <h2 className="text-4xl fs-sm font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        {/* <p className="text-base font-bodyFont leading-6 tracking-wide">
          I use animation as a third dimension by which to simplify experiences
          and kuiding thro each and every interaction. I'm not adding motion
          just to spruce things up, but doing it in ways that.
        </p> */}
        {/* <p className="text-base font-bodyFont leading-6 tracking-wide">
          Crafting interactive wonders, one component at a time, we're building
          the web's future pixel by pixel, turning your digital vision into
          reality.
        </p> */}
        Transforming complex challenges into seamless digital experiences, I
        bridge the gap between your vision and reality. With expertise in MERN
        and Laravel, I deliver scalable, high-performance solutions that drive
        your business forward.
      </div>
      {/* Media */}

      <Media />
    </div>
  );
};

export default LeftBanner;
