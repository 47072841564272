import { AiFillAppstore, AiFillSetting } from "react-icons/ai";
import { FaMobile, FaGlobe, FaTablet, FaServer } from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";
import { BsSpeedometer2 } from "react-icons/bs";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Web Development",
    des: "Delivering innovative web development solutions that exceed expectations and captivate users.",
  },
  {
    id: 2,
    icon: <AiFillAppstore />,
    title: "Single Page App",
    des: "Crafting seamless single-page applications that offer a fluid user experience, all in one place.",
  },
  {
    id: 3,
    // icon: <SiProgress />,
    icon: <FaTablet />,

    title: "Responsive Design",
    des: "Creating stunning, responsive designs that adapt seamlessly to any screen size, ensuring an optimal user experience on every device.",
  },
  {
    id: 4,
    icon: <FaServer />,
    title: "Backend Services",
    des: "Designing and implementing scalable server-side solutions with Node.js, Express.js, and Laravel to power your web applications.",
  },
  ,
  {
    id: 5,
    // icon: <SiAntdesign />,
    icon: <BsSpeedometer2 />,
    title: "Speed Optimization",
    des: "Maximizing website performance with meticulous speed optimization, ensuring your online presence stays cutting-edge",
  },
  {
    id: 6,
    // icon: <FaGlobe />,
    icon: <AiFillSetting />,
    title: "Maintenance",
    des: "Safeguarding your digital investments through maintenance for online security",
  },
];
