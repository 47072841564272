import React, { useState } from "react";
import Title from "../layouts/Title";
import {
  projectOne,
  projectTwo,
  projectThree,
  aStudio,
  innovate,
  lovely,
  forehub,
  kmwLegal,
  dashboard,
  podstarz,
  firstapproval,
  threeBids,
  omnifood,
  SpaceShuttle,
  intolerance,
  differentialDiagnois,
  blueRiver,
  hiringHelmet,
  utherverse,
  stardom,
  Nbr,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  const [showMore, setShowMore] = useState(false);

  // Projects data
  const projects = [
    {
      title: "DIFFERENTIAL DIAGNOSIS",
      des: "The Differential Diagnosis website offers resources for USMLE exam prep and residency interview preparation, especially for international medical graduates",
      src: differentialDiagnois,
      link: "https://differentialdiagnosis.net/",
    },
    {
      title: "HIRING HELMET",
      des: "Hiring Helmet provides a platform for managing job applications, streamlining the hiring process with tools for candidate tracking and communication.",
      src: hiringHelmet,
      link: "https://hiringhelmet.com/",
    },
    {
      title: "BLUE RIVER ANALYTICS",
      des: "Blue River Analytics provides data analytics and visualization solutions for the oil and gas industry, specializing in Spotfire consulting.",
      src: blueRiver,
      link: "https://blueriveranalytics.com/",
    },
    {
      title: "UTHERVERSE",
      des: "IDOL (Interactive Digital Online Life) by Utherverse offers a virtual world platform where users can create, explore, and interact in immersive 3D environments.",
      src: utherverse,
      link: "https://ido.utherverse.io/",
    },
    {
      title: "STARDOM TOKEN DAO",
      des: "Stardom Token DAO is a blockchain platform enabling decentralized collaboration and investment in the entertainment industry.",
      src: stardom,
      link: "https://www.stardomtokendao.io/",
    },
    {
      title: "NATIONAL BONSAI REGISTRY",
      des: "The National Bonsai Registry provides a platform for registering and showcasing bonsai collections, offering resources for bonsai enthusiasts.",
      src: Nbr,
      link: "https://www.nationalbonsairegistry.com/",
    },
    {
      title: "INNOVATE STUDIO",
      des: "Elevate your creativity with our innovative studio website, a hub for artistic inspiration and collaboration",
      src: innovate,
      link: "http://innovate.theexpertways.com/",
    },
    {
      title: "FirstApproval",
      des: "First Approval Source offers a range of services to suit your needs. We work hand-in-hand with our customers to ensure they receive fast, personalized service.",
      src: firstapproval,
      link: "https://firstapprovalsource.com/",
    },
    {
      title: "CINESTREAM",
      des: "Dive into a world of cinematic wonder with CineStream, your premier destination for streaming and exploring a treasure trove of movies online",
      src: lovely,
      link: "http://lovely-movies.theexpertways.com/",
    },
    {
      title: "FOREHUB VIEWTUBE",
      des: "Forehub, where your imagination finds its canvas, offering a platform to share and discover a world of videos online",
      src: forehub,
      link: "http://forehub.theexpertways.com/",
    },
    {
      title: "KMW LEGAL",
      des: "Your Trusted Advocates and Partners for Expert Legal Guidance and Representation in Legal Matters",
      src: kmwLegal,
      link: "http://kmwlegal.theexpertways.com/",
    },
    {
      title: "SHOPPY",
      des: "Your shipping management meets simplicity and efficiency. Take control of your logistics effortlessly with our intuitive tools and insights",
      src: dashboard,
    },
    {
      title: "PODSTARZ",
      des: "Bridging the Gap between Celebrities and Hosts, Creating Memorable Connections for Unforgettable Events",
      src: podstarz,
      link: "https://www.podstarz.net/",
    },
    {
      title: "3BIDS",
      des: "Your Gateway to the Perfect Job or Contract Project - Where talent meets opportunity, connecting job seekers and project creators seamlessly",
      src: threeBids,
      link: "https://3bids.netlify.app/",
    },
    {
      title: "OMNI FOOD",
      des: "Your Ultimate Destination for Gourmet Delights Delivered to Your Doorstep - Savor the flavors of the world with every bite, right at your fingertips",
      src: omnifood,
      link: "https://www.myyudy.gr/",
    },
    {
      title: "SPACE SHUTTLE",
      des: "Elevate Your Cargo Transport Experience to New Heights - Your trusted partner in efficient and reliable truck rentals for any haul",
      src: SpaceShuttle,
      link: "https://app.getspaceshuttle.com/",
    },
    {
      title: "INTOLERANCE",
      des: "The project designed to streamline the process of conducting laboratory tests from the comfort of one's home, offering convenience and accessibility to individuals seeking diagnostic services.",
      src: intolerance,
      link: "http://intolerance.theexpertways.com/",
    },
  ];

  // Determine how many projects to show based on the state
  const displayedProjects = showMore ? projects : projects.slice(0, 6);

  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black p-y-ss"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        {displayedProjects.map((project, index) => (
          <ProjectsCard
            key={index}
            title={project.title}
            des={project.des}
            src={project.src}
            link={project.link}
          />
        ))}
      </div>
      {!showMore && (
        <div className="flex justify-center mt-8">
          <button
            onClick={() => setShowMore(true)}
            className="px-6 py-2 text-white bg-[#ff0046] rounded-md hover:bg-[#e21d51]"
          >
            Show More
          </button>
        </div>
      )}
    </section>
  );
};

export default Projects;
