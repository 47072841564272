import React, {  useState } from 'react'
import Title from '../layouts/Title';
import Education from './Education';
import Skills from './Skills';
import Achievement from './Achievement';
import Experience from "./Experience"

const Resume = () => {
  //  const [educationData, setEducationData] = useState(true);
  //  const [skillData, setSkillData] = useState(false);
  //  const [experienceData, setExperienceData] = useState(false);
  //  const [achievementData, setAchievementData] = useState(false);
  return (
    <section id="resume" className="w-full py-20 border-b-[1px] border-b-black p-y-ss">
      <div className="flex justify-center items-center text-center">
        <Title title="WITH YEARS OF EXPERIENCE" des="PROFESSIONAL SKILLS" />
      </div>
      <div>
        <ul className="w-full">
        {<Skills />}
      
        </ul>
      </div>
      {/* {educationData && <Education />} */}
      {/* {skillData && <Skills />} */}
      {/* {achievementData && <Achievement />}
      {experienceData && <Experience />} */}
 
    </section>
  );
}

export default Resume