import React from "react";
import Title from "../layouts/Title";

const About = () => {
  return (
    <section
      id="about"
      className="w-full py-20 border-b-[1px] border-b-black p-y-ss"
    >
      <Title title="About me" des="Who I Am" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20"></div>
      <div>
        <h2 className="text-base font-bodyFont tracking-wide leading-8">
          Hi there! I'm Muneeb,{" "}
          <span className="text-designColor">
            a Full Stack Software Engineer
          </span>{" "}
          with expertise in{" "}
          <span className="text-designColor">
            MERN Stack, Laravel and Wordpress.
          </span>{" "}
          I specialize in building end-to-end web applications that are both
          robust and user-friendly. From{" "}
          <span className="text-designColor"> backend architecture </span> to
          <span className="text-designColor">
            {" "}
            responsive frontend design,
          </span>{" "}
          I ensure that every project is
          <span className="text-designColor"> scalable, efficient, </span> and
          aligned with your{" "}
          <span className="text-designColor"> business goals. </span>
          {/* <span className="text-designColor">
            {" "}
            dynamic web applications{" "}
          </span>{" "}
          or crafting seamless{" "}
          <span className="text-designColor"> mobile experiences </span>, I'm
          <span className="text-designColor">
            committed to delivering excellence{" "}
          </span>{" "} */}
          My passion for clean code and{" "}
          <span className="text-designColor"> innovative solutions </span>{" "}
          drives me to turn complex challenges into{" "}
          <span className="text-designColor">
            {" "}
            seamless digital experiences.
          </span>{" "}
          Let's collaborate to bring your{" "}
          <span className="text-designColor"> ideas to life </span> in the
          digital world.<span className="text-designColor"> Welcome </span> to
          my world of{" "}
          <span className="text-designColor"> full-stack excellence.</span>
          {/* <span className="text-designColor"> user-friendly solutions </span>{" "}
          that stand out in the digital realm. Explore my portfolio to see the{" "}
          <span className="text-designColor">magic of React and Next.js </span>{" "}
          come to life, or dive into the world of{" "}
          <span className="text-designColor">
            {" "}
            mobile development with React Native.{" "}
          </span>
          Let's collaborate to bring your ideas to the forefront of the digital
          landscape. <span className="text-designColor"> Welcome </span>to my
          world of frontend development excellence. */}
        </h2>
      </div>
    </section>
  );
};

export default About;

// Hi there! I'm Muneeb, a Full Stack Software Engineer with expertise in MERN and Laravel. I specialize in building end-to-end web applications that are both robust and user-friendly. From backend architecture to responsive frontend design, I ensure that every project is scalable, efficient, and aligned with your business goals. My passion for clean code and innovative solutions drives me to turn complex challenges into seamless digital experiences. Let's collaborate to bring your ideas to life in the digital world. Welcome to my world of full-stack excellence.
