import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";
import { FaWhatsapp } from "react-icons/fa";

import { CgMail } from "react-icons/cg";
const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center mb-sm-8">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Muneeb u Rahman</h3>
        <p className="text-lg font-normal text-[#FF014F]">
          Software Engineer | MERN & Laravel Expert{" "}
        </p>
        <p className="text-base text-gray-400 tracking-wide">
          A Full Stack Software Engineer builds complete web solutions, crafting
          seamless user experiences with MERN Stack, Laravel and Wordpress.{" "}
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          <FaWhatsapp color="#FF014F" size={25} />:
          <span className="text-lightText">+92 3475308533</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          <CgMail color="#FF014F" size={25} />:
          <span className="text-lightText">muneeb.dev09@gmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
          {/* <span className="bannerIcon">
            <FaFacebookF />
          </span>
          <span className="bannerIcon">
            <FaTwitter />
          </span> */}
          <span className="bannerIcon">
            <a
              href="https://www.linkedin.com/in/muneeb-u-rahman/"
              target="_blank"
            >
              {" "}
              <FaLinkedinIn />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactLeft;
